<template>
    <div>
        <div style="display:flex">
            <div class="left-body">
                <div style="display:none">
                    <div id="Rate" v-if="pRateAll_ThanhLanh.Rate">
                        <table style="width:100%;border: none">
                            <tr style="border:none">
                                <th v-if="LangOfReport.LangId == 1000000" style="width:70%;text-align: center;border:none">Chi tiết giá</th>
                                <th v-else style="width:70%;text-align: center;border:none">RateCode Detail</th>
                                <th v-if="LangOfReport.LangId == 1000000" style="width:30%;text-align: center;border:none">Tổng</th>
                                <th v-else style="width:30%;text-align: center;border:none">Total</th>
                            </tr>
                            <tr v-for="(item, index) in pRateAll_ThanhLanh.Rate" :key="index + 'rate'" style="border:none">
                                <td style="width:70%;text-align: center;border:none">{{ item.RateName }}</td>
                                <td style="width:30%;text-align: center;border:none">{{ formatPrice(item.AmountRate) }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div style="display:none">
                    <div id="Payment" v-if="pServiceAll_ThanhLanh.Service">
                        <table style="width:100%;border: none;">
                            <tr style="border:none">
                                <th v-if="LangOfReport.LangId == 1000000" style="width:35%;text-align: center;border:none">Tên dịch vụ</th>
                                <th v-else style="width:35%;text-align: center;border:none">Service Name</th>
                                <th v-if="LangOfReport.LangId == 1000000" style="width:35%;text-align: center;border:none">Tổng</th>
                                <th v-else style="width:35%;text-align: center;border:none">Total</th>
                                <th v-if="LangOfReport.LangId == 1000000" style="width:30%;text-align: center;border:none">Số lượng</th>
                                <th v-else style="width:30%;text-align: center;border:none">Quantity</th>
                            </tr>
                            <tr v-for="(item, index) in pServiceAll_ThanhLanh.Service" :key="index + 'service'">
                                <td style="width:35%;text-align: center;border:none">{{ item.ServiceName }}</td>
                                <td style="width:35%;text-align: center;border:none">{{ formatPrice(item.TotalAmount) }}
                                </td>
                                <td style="width:30%;text-align: center;border:none">{{ item.Quantity }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div style="display:none">
                    <div id="listMember">
                        <table style="width:100%">
                            <tr style="border:none">
                                <th v-if="LangOfReport.LangId == 1000000" style="width:30%;text-align: center;border:none">Tên Golfer</th>
                                <th v-else style="width:30%;text-align: center;border:none">Golfer Name</th>
                                <th style="width:15%;text-align: center;border:none">BDC</th>
                                <th v-if="LangOfReport.LangId == 1000000" style="width:20%;text-align: center;border:none">Ngày chơi</th>
                                <th v-else style="width:20%;text-align: center;border:none">OpenDate</th>
                                <th style="width:20%;text-align: center;border:none">TeeTime</th>
                                <th v-if="LangOfReport.LangId == 1000000" style="width:15%;text-align: center;border:none">Số hố</th>
                                <th v-else style="width:15%;text-align: center;border:none">Hole</th>
                            </tr>
                            <tr v-for="(item, index) in listBookingDetail" :key="index + 'bkd'">
                                <td style="width:30%;text-align: center;border:none">{{ item.FullName }}</td>
                                <td style="width:15%;text-align: center;border:none">{{ item.BookingDetailCode }}</td>
                                <td style="width:20%;text-align: center;border:none">{{
                                    convertUTC(item.OpenDateTime, 'DD/MM/YYYY') }}</td>
                                <td style="width:20%;text-align: center;border:none">{{
                                    convertUTC(item.OpenDateTime, 'HH:mm') }} - {{ item.TeeName }}</td>
                                <td style="width:15%;text-align: center;border:none">{{ item.TotalNumberOfHole }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div style="display:none">
                    <div id="listMemberVY">
                        <table style="width:100%">
                            <tr style="border:none">
                                <th v-if="LangOfReport.LangId == 1000000" style="width:15%;text-align: center;border:none">Ngày chơi</th>
                                <th v-else style="width:13%;text-align: center;border:none">OpenDate</th>

                                <th style="width:13%;text-align: center;border:none">TeeTime</th>

                                <th v-if="LangOfReport.LangId == 1000000" style="width:10%;text-align: center;border:none">Số hố</th>
                                <th v-else style="width:5%;text-align: center;border:none">Hole</th>

                                <th v-if="LangOfReport.LangId == 1000000" style="width:20%;text-align: center;border:none">Tên Golfer</th>
                                <th v-else style="width:20%;text-align: center;border:none">Golfer Name</th>

                                <th v-if="LangOfReport.LangId == 1000000" style="width:10%;text-align: center;border:none">Đặt caddy</th>
                                <th v-else style="width:9%;text-align: center;border:none">Booking Caddy</th>
                                
                                <th v-if="LangOfReport.LangId == 1000000" style="width:20%;text-align: center;border:none">Phí golf</th>
                                <th v-else style="width:20%;text-align: center;border:none">Golf Fee</th>

                                <th v-if="LangOfReport.LangId == 1000000" style="width:20%;text-align: center;border:none">Tổng chi phí</th>
                                <th v-else style="width:20%;text-align: center;border:none">Total Fee</th>
                            </tr>
                            <tr v-for="(item, index) in listBookingDetail" :key="index + 'bkd'">
                                <td style="width:13%;text-align: center;border:none">{{
                                    convertUTC(item.OpenDateTime, 'DD/MM/YYYY') }}</td>

                                <td style="width:13%;text-align: center;border:none">
                                    {{ convertUTC(item.OpenDateTime, 'HH:mm') }} - {{ item.TeeName }}
                                </td>

                                <td style="width:5%;text-align: center;border:none">{{ item.TotalNumberOfHole }}</td>

                                <td style="width:20%;text-align: center;border:none">{{ item.FullName }}</td>

                                <td style="width:9%;text-align: center;border:none">{{ item.BookingDetailCaddy[0] ? (item.BookingDetailCaddy[0].IsBooked ? item.BookingDetailCaddy[0].CaddyCode : '') : '' }}</td>
                                
                                <td style="width:20%;text-align: center;border:none">{{ formatPrice(item.TotalGolfFee) }}</td>
                                
                                <td style="width:20%;text-align: center;border:none">{{ formatPrice(item.TotalAmount) }}</td>
                            </tr>
                        </table>
                    </div>
                </div>

                <!-- Email nhóm -->
                <div style="display:none">
                    <div id="RateEmailSelect" v-if="dataEmailGroupSelect.Rate">
                        <table style="width:100%;border: none">
                            <tr style="border:none">
                                <th v-if="LangOfReport.LangId == 1000000" style="width:70%;text-align: center;border:none">Chi tiết giá</th>
                                <th v-else style="width:70%;text-align: center;border:none">RateCode Detail</th>
                                <th v-if="LangOfReport.LangId == 1000000" style="width:30%;text-align: center;border:none">Tổng</th>
                                <th v-else style="width:30%;text-align: center;border:none">Total</th>
                            </tr>
                            <tr v-for="(item, index) in dataEmailGroupSelect.Rate" :key="index + 'rate'" style="border:none">
                                <td style="width:70%;text-align: center;border:none">{{ item.RateName }}</td>
                                <td style="width:30%;text-align: center;border:none">{{ formatPrice(item.AmountRate) }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div style="display:none">
                    <div id="PaymentEmailSelect" v-if="dataEmailGroupSelect.Service">
                        <table style="width:100%;border: none;">
                            <tr style="border:none">
                                <th v-if="LangOfReport.LangId == 1000000" style="width:35%;text-align: center;border:none">Tên dịch vụ</th>
                                <th v-else style="width:35%;text-align: center;border:none">Service Name</th>
                                <th v-if="LangOfReport.LangId == 1000000" style="width:35%;text-align: center;border:none">Tổng</th>
                                <th v-else style="width:35%;text-align: center;border:none">Total</th>
                                <th v-if="LangOfReport.LangId == 1000000" style="width:30%;text-align: center;border:none">Số lượng</th>
                                <th v-else style="width:30%;text-align: center;border:none">Quantity</th>
                            </tr>
                            <tr v-for="(item, index) in dataEmailGroupSelect.Service" :key="index + 'service'">
                                <td style="width:35%;text-align: center;border:none">{{ item.ServiceName }}</td>
                                <td style="width:35%;text-align: center;border:none">{{ formatPrice(item.TotalAmount) }}
                                </td>
                                <td style="width:30%;text-align: center;border:none">{{ item.Quantity }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div style="display:none" v-if="dataEmailGroupSelect.Member">
                    <div id="listMemberEmailSelect">
                        <table style="width:100%">
                            <tr style="border:none">
                                <th v-if="LangOfReport.LangId == 1000000" style="width:30%;text-align: center;border:none">Tên Golfer</th>
                                <th v-else style="width:30%;text-align: center;border:none">Golfer Name</th>
                                <th style="width:15%;text-align: center;border:none">BDC</th>
                                <th v-if="LangOfReport.LangId == 1000000" style="width:20%;text-align: center;border:none">Ngày chơi</th>
                                <th v-else style="width:20%;text-align: center;border:none">OpenDate</th>
                                <th style="width:20%;text-align: center;border:none">TeeTime</th>
                                <th v-if="LangOfReport.LangId == 1000000" style="width:15%;text-align: center;border:none">Số hố</th>
                                <th v-else style="width:15%;text-align: center;border:none">Hole</th>
                            </tr>
                            <tr v-for="(item, index) in dataEmailGroupSelect.Member" :key="index + 'bkd'">
                                <td style="width:30%;text-align: center;border:none">{{ item.FullName }}</td>
                                <td style="width:15%;text-align: center;border:none">{{ item.BookingDetailCode }}</td>
                                <td style="width:20%;text-align: center;border:none">{{
                                    convertUTC(item.OpenDateTime, 'DD/MM/YYYY') }}</td>
                                <td style="width:20%;text-align: center;border:none">{{
                                    convertUTC(item.OpenDateTime, 'HH:mm') }} - {{ item.TeeName }}</td>
                                <td style="width:15%;text-align: center;border:none">{{ item.TotalNumberOfHole }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div style="display:none" v-if="dataEmailGroupSelect.Member">
                    <div id="listMemberVYEmailSelect">
                        <table style="width:100%">
                            <tr style="border:none">
                                <th v-if="LangOfReport.LangId == 1000000" style="width:15%;text-align: center;border:none">Ngày chơi</th>
                                <th v-else style="width:15%;text-align: center;border:none">OpenDate</th>

                                <th style="width:15%;text-align: center;border:none">TeeTime</th>

                                <th v-if="LangOfReport.LangId == 1000000" style="width:10%;text-align: center;border:none">Số hố</th>
                                <th v-else style="width:10%;text-align: center;border:none">Hole</th>

                                <th v-if="LangOfReport.LangId == 1000000" style="width:20%;text-align: center;border:none">Tên Golfer</th>
                                <th v-else style="width:20%;text-align: center;border:none">Golfer Name</th>

                                <th v-if="LangOfReport.LangId == 1000000" style="width:10%;text-align: center;border:none">Đặt caddy</th>
                                <th v-else style="width:10%;text-align: center;border:none">Booking Caddy</th>
                                
                                <th v-if="LangOfReport.LangId == 1000000" style="width:15%;text-align: center;border:none">Phí golf</th>
                                <th v-else style="width:15%;text-align: center;border:none">Golf Fee</th>

                                <th v-if="LangOfReport.LangId == 1000000" style="width:15%;text-align: center;border:none">Tổng chi phí</th>
                                <th v-else style="width:15%;text-align: center;border:none">Total Fee</th>
                            </tr>
                            <tr v-for="(item, index) in dataEmailGroupSelect.Member" :key="index + 'bkd'">
                                <td style="width:20%;text-align: center;border:none">{{
                                    convertUTC(item.OpenDateTime, 'DD/MM/YYYY') }}</td>

                                <td style="width:20%;text-align: center;border:none">
                                    {{ convertUTC(item.OpenDateTime, 'HH:mm') }} - {{ item.TeeName }}
                                </td>

                                <td style="width:15%;text-align: center;border:none">{{ item.TotalNumberOfHole }}</td>

                                <td style="width:30%;text-align: center;border:none">{{ item.FullName }}</td>

                                <td style="width:15%;text-align: center;border:none">{{ item.BookingDetailCaddy[0] ? (item.BookingDetailCaddy[0].IsBooked ? item.BookingDetailCaddy[0].CaddyCode : '') : '' }}</td>
                                
                                <td style="width:15%;text-align: center;border:none">{{ formatPrice(item.TotalGolfFee) }}</td>
                                
                                <td style="width:15%;text-align: center;border:none">{{ formatPrice(item.TotalAmount) }}</td>
                            </tr>
                        </table>
                    </div>
                </div>






                <div class="pr-1">
                    <v-select v-model="BookingDetailIdSelect" label="FullName" :options="listBookingDetail"
                        :reduce="x => x.Id" :clearable="false" placeholder="Tìm kiếm tên, BBC"
                        @input="viewEmailDetail(BookingDetailIdSelect)" />
                    <div style="overflow-y: auto; height: 760px;">
                        <div style="border-bottom: 1px solid black;padding-bottom: 5px;">
                            <b-button class="print" variant="primary" @click="EmailGroup()" style="margin-top: 10px">{{ $t('golf_booking_email_group') }}</b-button>
                            
                        </div>
                        <div class="d-flex">
                            <b-form-checkbox v-model="checkAll" class="mt-auto mb-auto" @change="checkAllListBookingDetail(checkAll)"/>
                            <b-button class="print" variant="primary" @click="EmailGroupSelect()" style="margin-top: 10px">{{ $t('golf_booking_email_select_group') }}</b-button>
                        </div>
                        <div v-for="(items, index) in listBookingDetail" :key="index + 'bkdemail'" class="d-flex">
                            <b-form-checkbox v-model="items.check" class="mt-auto mb-auto"/>
                            <b-button class="print" variant="primary" style="margin-top: 10px"
                                @click="viewEmailDetail(items.Id)">
                                <div style="padding: 5px 0 5px 0">
                                    {{ items.BookingDetailCode }} - {{ items.FullName }}
                                </div>
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="right-body">
                <b-overlay style="height:70%" :show="isLoadding" :opacity="0.3" rounded="sm">
                    <div class="top-content">
                        <b-form-checkbox v-if="isShowCheckBoxRate == 1" style="margin:auto 10px auto 0" v-model="isShowRate">
                            <p class="m-auto text-all">
                                {{ $t('golf_booking_email_show_rate') }}
                            </p>
                        </b-form-checkbox>
                        <span style="margin:auto 0">{{ $t('golf_booking_email_lang_email') }}: </span>
                        <v-select v-model="LangOfReport" class="ml-1" label="Description"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="listLanguages" :multiple="false"
                            style="min-width: 150px" :clearable="false" @input="changeLanguage(LangOfReport)">
                            <template #selected-option="{ WordTranslate, LangCode }">
                                <b-img style="margin-right:5px;width: 20px;"
                                    :src="require(`@/assets/images/flags/${WordTranslate}.svg`)" width="22px" />
                                {{ LangCode }}
                            </template>
                            <template v-slot:option="item">
                                <b-img style="margin-right:5px;width: 20px;"
                                    :src="require(`@/assets/images/flags/${item.WordTranslate}.svg`)" width="22px" />
                                {{ item.LangCode }}
                            </template>
                        </v-select>
                        <b-button variant="primary" style="margin-left: 20px" @click="ExportPDF()">
                            {{ $t('golf_common_export_to_pdf') }}
                        </b-button>
                        <b-button variant="primary" style="margin-left: 20px" @click="openModalSendEmail()">
                            {{ $t("golf_confirm_email_booking") }}
                        </b-button>
                    </div>
                    <!-- <iframe style="width:100%;height: 100%;" class="mt-1" :src="urlPDF"></iframe> -->
                    <VueHtml2pdf :show-layout="true" :enable-download="true" :manual-pagination="true" ref="html2Pdf"
                        :filename="TypeEmail == 'BOOKED_GROUP' ? `ConfirmBookingGroup${DataBooking ? DataBooking.BookingCode : ''}` : `ConfirmBooking${BookingDetail ? BookingDetail.BDC : ''}`"
                        >
                        <section slot="pdf-content">
                            <div v-html="htmlEmail"></div>
                        </section>
                    </VueHtml2pdf>
                </b-overlay>

                <!-- <div v-if="urlPDF">
                        <pdf :src="urlPDF"></pdf>
                    </div> -->
                <SendEmail ref="SendEmail" :bodyEmail="htmlEmail"
                    :data="TypeEmail === 'BOOKED' ? BookingDetail : TypeEmail === 'BOOKED_GROUP' ? DataBooking : null"
                    :type="TypeEmail" />
            </div>

        </div>
    </div>
</template>
<script>
import SendEmail from '@/views/modules/booking/Email/SendEmail'
import { ROOT, REPORT_URL } from "@/api/constant";
import { localize } from 'vee-validate'
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import { booking } from "@/api/booking";
import { commonServices } from '@/api/common-services'
import jQuery from "jquery";
import "@/assets/js/telerikReportViewer-17.0.23.118.min.js";
import dayjs from 'dayjs'

window.$ = jQuery;
export default {
    components: {
        BNavItemDropdown,
        BDropdownItem,
        BImg,
        SendEmail,
    },
    data() {
        return {
            listLanguages: [],
            listBookingDetail: [],
            RateInBooking: [],
            OtherServiceBooking: [],
            pRateAll_ThanhLanh: {},
            pServiceAll_ThanhLanh: {},
            DataBooking: null,
            pToken: null,
            pLang: null,
            pTimeZone: null,
            pTimeZoneOffset: null,
            pUrlReport: null,
            pUrlCourse: null,
            pUrlSite: null,
            pHotelId: null,
            pUrlService: null,
            listFileName: null,
            pUrlMember: null,
            pUrlFolio: null,
            pBookingDetail: null,
            fileName: null,
            urlPDF: null,
            BookingDetailId: null,
            htmlEmailConfirm: null,
            LangOfReport: {
                LangId: null
            },
            listDataVie: null,
            listDataEng: null,
            htmlEmail: null,
            BookingDetail: null,
            isLoadding: false,
            dataProp: null,
            TypeEmail: null,
            BookingDetailIdSelect: null,
            urlPDFsave: null,
            isShowRate: true,
            isShowCheckBoxRate: null,
            dataEmailGroupSelect: {
                Member: null,
                Service: null,
                Rate: null
            },
            checkAll: false
        }
    },
    // computed: {
    // currentLocale() {
    //     return this.listLanguages.find(l => l.LangId == this.$i18n.locale)
    //     },
    // },
    async created() {
        this.getConfigInfo_RCOM01()
        await this.getListLanguages()
        // Set bản dịch validate theo ngôn ngữ đã chọn
        if (this.$i18n.locale == 1000001) {
            localize('en')
        } else {
            localize('vi')
        }
        await this.OpenForm()
    },
    watch: {
        isShowRate() {
            if (this.TypeEmail === 'BOOKED') {
                this.replaceBookedDetail()
            } else {
                this.EmailGroup()
            }
        }
    },
    methods: {
        async getConfigInfo_RCOM01() {
            const response = await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' })
            if (response.Status === '200') {
                this.isShowCheckBoxRate = response?.Data.find(x => x.KeyCode === 'BOOKING_EMAIL_SHOW_CHECK_BOX_RATE').KeyValue
            }
        },
        async getListLanguages() {
            await commonServices.getLanguages()
                .then(res => {
                    if (res) {
                        this.listLanguages = res.Data.LangCategory.filter(x => x.Active == true)
                        this.LangOfReport = this.listLanguages.find(l => l.LangId == this.$i18n.locale)
                    }
                })
        },
        async getServiceInBooking() {
            const data = {
                BDC: [],
                Bagtag: [],
                BookingCode: this.$route.params.BookingCode,
                GroupName: "",
                KeyWord: "",
                OpenDate: this.getUTC('start', 'day'),
            }
            await booking.api_RBK02D(data).then(res => {
                res.Data.BookingDetail.filter(item => item.BookingDetailStatus != 'CANCELLED').forEach(x => {
                    var GolfFee = []
                    var OtherService = []
                    x.BookingDetailServices.forEach(y => {
                        if (y.RateId) {
                            GolfFee.push({
                                RateName: y.RateName,
                                Amount: y.Amount,
                                RateId: y.RateId
                            })
                        } else {
                            OtherService.push({
                                ServiceId: y.ServiceId,
                                ServiceName: y.ServiceName,
                                TotalAmount: y.TotalAmount,
                                Quantity: y.Quantity
                            })
                        }
                    })
                    var TotalGolfFee = 0
                    let TotalOtherFee = 0
                    GolfFee.map(x => {
                        TotalGolfFee += x.Amount
                    })
                    OtherService.map(x => {
                        TotalOtherFee += x.TotalAmount
                    })
                    this.listBookingDetail.find(bkdetail => bkdetail.Id == x.Id).TotalGolfFee = TotalGolfFee
                    this.listBookingDetail.find(bkdetail => bkdetail.Id == x.Id).TotalAmount = TotalGolfFee + TotalOtherFee
                    this.RateInBooking.push({
                        BookingDetailId: x.Id,
                        Rate: GolfFee
                    })
                    this.OtherServiceBooking.push({
                        BookingDetailId: x.Id,
                        Service: OtherService
                    })
                })
                var RateAll = []
                var ServiceAll = []
                var TotalGolfFee = 0
                var QuantityGolfFee = 0
                this.RateInBooking.forEach(x => {
                    QuantityGolfFee++
                    var AmountRate = 0
                    x.Rate.forEach(y => {
                        TotalGolfFee += y.Amount
                        AmountRate += y.Amount
                    })
                    if (!RateAll.find(k => k.RateId === x.Rate[0].RateId)) {
                        RateAll.push({
                            RateId: x.Rate[0].RateId,
                            RateName: x.Rate[0].RateName,
                            AmountRate: AmountRate
                        })
                    }
                })
                ServiceAll.push({
                    ServiceId: -1,
                    ServiceName: 'Phí dịch vụ Golf',
                    TotalAmount: TotalGolfFee,
                    Quantity: QuantityGolfFee
                })
                this.OtherServiceBooking.forEach(x => {
                    x.Service.forEach(y => {
                        if (ServiceAll.length === 0) {
                            ServiceAll.push(JSON.parse(JSON.stringify(y)))
                        } else {
                            if (ServiceAll.find(z => z.ServiceId === y.ServiceId)) {
                                ServiceAll.forEach(k => {
                                    if (k.ServiceId === y.ServiceId) {
                                        k.Quantity += y.Quantity
                                        k.TotalAmount += y.TotalAmount
                                    }
                                })
                            } else {
                                ServiceAll.push(JSON.parse(JSON.stringify(y)))
                            }
                        }
                    })
                })
                this.pRateAll_ThanhLanh.Rate = RateAll
                this.pServiceAll_ThanhLanh.Service = ServiceAll
                this.$forceUpdate()
            })
        },
        async api_RBK02A() {
            this.BookingCode = this.$route.params.BookingCode
            const body = {
                KeyWord: "",
                BookingCode: this.BookingCode,
                BookingDetail: null,
                BookingId: null,
                BookingDetailStatus: ["BOOKED", "CHECK_IN", "CHECK_OUT","NO_SHOW"],
                BDC: [],
                IsGetCaddy: true
            }
            var TotalGolfer = 0
            await booking.getDetailBooking2A(body).then(res => {
                var TeeTimeAll = ''
                var SumTotalPriceAll = 0
                var ListCaddyCode = []
                res.Data.TeeTime.forEach(x => {
                    TeeTimeAll = TeeTimeAll + ' ' + `${x.TeeName} - ${this.convertUTC(x.OpenDate, "HH:mm")} `
                    x.Booking.forEach(y => {
                        SumTotalPriceAll += y.TotalPrice
                        y.BookingDetail.forEach(z => {
                            TotalGolfer += 1
                            z.TeeName = x.TeeName
                            z.check = false
                            this.listBookingDetail.push(z)
                            z.BookingDetailCaddy.forEach(k => {
                                ListCaddyCode.push(k.CaddyCode)
                            })
                        })
                    })
                })
                this.DataBooking = {
                    DateConfirm: dayjs().format("DD/MM/YYYY"),
                    BookingId: res.Data.TeeTime[0].Booking[0].Id,
                    BookingCode: res.Data.TeeTime[0].Booking[0].BookingCode,
                    GroupName: res.Data.TeeTime[0].Booking[0].GroupName ? res.Data.TeeTime[0].Booking[0].GroupName : '',
                    TotalOfGolfer: TotalGolfer,
                    RepresentativeName: res.Data.TeeTime[0].Booking[0].RepresentativeName ? res.Data.TeeTime[0].Booking[0].RepresentativeName : '',
                    RepresentativePhoneNumber: res.Data.TeeTime[0].Booking[0].RepresentativePhoneNumber ? res.Data.TeeTime[0].Booking[0].RepresentativePhoneNumber : '',
                    RepresentativeEmail: res.Data.TeeTime[0].Booking[0].RepresentativeEmail ? res.Data.TeeTime[0].Booking[0].RepresentativeEmail : '',
                    RepresentativeNote: res.Data.TeeTime[0].Booking[0].RepresentativeNote ? res.Data.TeeTime[0].Booking[0].RepresentativeNote	: '',
                    TeeTime: TeeTimeAll,
                    TotalPriceAll: this.formatPrice(SumTotalPriceAll),
                    OpenDate: this.convertUTC(res.Data.TeeTime[0].OpenDate, "DD/MM/YYYY"),
                    Caddy: ListCaddyCode.join(),
                    UserName: JSON.parse(localStorage.getItem('userData')).userName
                }
            })

        },
        async OpenForm() {
            this.isLoadding = true
            await this.api_RBK02A()
            await this.getServiceInBooking()
            await this.api_RCOM01()
        },
        async api_RCOM01() {
            await this.api_RCOM01_Vie()
            await this.api_RCOM01_Eng()
            if (this.$route.query.BookingDetailId) {
                await this.viewEmailDetail(Number(this.$route.query.BookingDetailId))
            } else {
                await this.EmailGroup()
            }
        },
        async api_RCOM01_Vie() {
            const body = {
                KeyGroup: "EMAIL_CONFIRMATION"
            }
            await commonServices.getCommon(body).then(response => {
                this.listDataVie = response.Data
                this.DataBooking.Url = this.listDataVie.find(x => x.KeyCode == "URL_IMAGE").KeyValue
            })
        },
        replaceBookedGroup(data) {
            this.TypeEmail = 'BOOKED_GROUP'
            var ob = data
            ob = ob.replace('{ ListMember }', document.getElementById('listMember').innerHTML)
            ob = ob.replace('{ ListMemberVY }', document.getElementById('listMemberVY').innerHTML)
            if(this.isShowRate) {
                ob = ob.replace('{ Rate }', document.getElementById('Rate').innerHTML)
                ob = ob.replace('{ Payment }', document.getElementById('Payment').innerHTML)
            } else {
                ob = ob.replace('{ Rate }', '')
                ob = ob.replace('{ Payment }', '')
            }
            for (const key in this.DataBooking) {
                if (Object.hasOwnProperty.call(this.DataBooking, key)) {
                    const element = this.DataBooking[key];
                    var exp = new RegExp(`{ ${key} }`, "g");
                    ob = ob.replace(exp, element);
                }
            }
            this.htmlEmail = ob
            const newBlob = new Blob([ob], {
                type: 'text/html',
            })
            this.urlPDF = window.URL.createObjectURL(newBlob)
            this.isLoadding = false
        },
        
        async api_RCOM01_Eng() {
            const body = {
                KeyGroup: "EMAIL_CONFIRMATION",
                LangOfData: "1000001"
            }
            await commonServices.getCommon(body).then(response => {
                this.listDataEng = response.Data

            })
        },
        changeLanguage() {
            if (this.TypeEmail === 'BOOKED') {
                this.replaceBookedDetail()
            } else {
                this.EmailGroup()
            }
        },
        openModalSendEmail() {
            this.$refs.SendEmail.open()
        },
        async viewEmailDetail(BookingDetailId) {
            this.isLoadding = true
            await this.call_api_RBK02B(BookingDetailId)

        },
        async call_api_RBK02B(Id) {
            const listMemberSelect = this.listBookingDetail.filter(x => x.Id == Id).map(y => y.Id)
            const RateInBookingtemp = this.RateInBooking.filter(x => listMemberSelect.find(y => y == x.BookingDetailId))
            const ServiceInBookingtemp = this.OtherServiceBooking.filter(x => listMemberSelect.find(y => y == x.BookingDetailId))
            var RateAll = []
            var ServiceAll = []
            var TotalGolfFee = 0
            var QuantityGolfFee = 0
            RateInBookingtemp.forEach(x => {
                QuantityGolfFee++
                var AmountRate = 0
                x.Rate.forEach(y => {
                    TotalGolfFee += y.Amount
                    AmountRate += y.Amount
                })
                if (!RateAll.find(k => k.RateId === x.Rate[0].RateId)) {
                    RateAll.push({
                        RateId: x.Rate[0].RateId,
                        RateName: x.Rate[0].RateName,
                        AmountRate: AmountRate
                    })
                }
            })
            ServiceAll.push({
                ServiceId: -1,
                ServiceName: 'Phí dịch vụ Golf',
                TotalAmount: TotalGolfFee,
                Quantity: QuantityGolfFee
            })
            ServiceInBookingtemp.forEach(x => {
                x.Service.forEach(y => {
                    if (ServiceAll.length === 0) {
                        ServiceAll.push(JSON.parse(JSON.stringify(y)))
                    } else {
                        if (ServiceAll.find(z => z.ServiceId === y.ServiceId)) {
                            ServiceAll.forEach(k => {
                                if (k.ServiceId === y.ServiceId) {
                                    k.Quantity += y.Quantity
                                    k.TotalAmount += y.TotalAmount
                                }
                            })
                        } else {
                            ServiceAll.push(JSON.parse(JSON.stringify(y)))
                        }
                    }
                })
            })
            this.dataEmailGroupSelect.Service = ServiceAll
            this.dataEmailGroupSelect.Member = this.listBookingDetail.filter(x => x.check)
            this.dataEmailGroupSelect.Rate = RateAll
            const body = {
                BookingDetail: {
                    Id: Id
                }
            }
            await booking.getDetailBooking2B(body).then(response => {
                var CaddyText = ''
                this.BookingDetail = response.Data.Booking.BookingDetail;
                this.BookingDetail.BookingDetailCaddy.forEach((x,index) => {
                    if(index === 0) {
                        CaddyText += x.CaddyCode 
                    } else {
                        CaddyText = CaddyText + ',' + x.CaddyCode 
                    }
                })
                this.BookingDetail.Caddy = CaddyText
                this.BookingDetail.OpenDateHour = this.convertUTC(this.BookingDetail.OpenDate, "HH:mm");
                this.BookingDetail.OpenDate = this.convertUTC(
                    this.BookingDetail.OpenDate,
                    "DD/MM/YYYY"
                );
                this.BookingDetail.TotalPrice = this.formatPrice(this.BookingDetail.TotalPrice)
                // this.BookingDetail.Caddy
                this.replaceBookedDetail()
            })
        },
        async replaceBookedDetail() {
            this.TypeEmail = 'BOOKED'
            if (this.LangOfReport.LangId == 1000000) {
                var htmlDetail = this.listDataVie.find(x => x.KeyCode == "BOOKED").KeyValue + this.listDataVie.find(x => x.KeyCode == "BOOKED2").KeyValue
            } else {
                var htmlDetail = this.listDataEng.find(x => x.KeyCode == "BOOKED").KeyValue + this.listDataEng.find(x => x.KeyCode == "BOOKED2").KeyValue
            }
            var ob = await this.unzipData(htmlDetail)
            if(this.isShowRate) {
                if (ob.includes("{ ListMember }")) {
                ob = ob.replace('{ ListMember }', document.getElementById('listMemberEmailSelect').innerHTML)
                }
                if (ob.includes("{ ListMemberVY }")) {
                ob = ob.replace('{ ListMemberVY }', document.getElementById('listMemberVYEmailSelect').innerHTML)
                }
                if (ob.includes("{ Rate }")) {
                    ob = ob.replace('{ Rate }', document.getElementById('RateEmailSelect').innerHTML)
                }
                if (ob.includes("{ Payment }")) {
                    ob = ob.replace('{ Payment }', document.getElementById('PaymentEmailSelect').innerHTML)
                }
            } else {
                if (ob.includes("{ ListMember }")) {
                    ob = ob.replace('{ ListMember }', '')
                }
                if (ob.includes("{ ListMemberVY }")) {
                    ob = ob.replace('{ ListMemberVY }', '')
                }
                if (ob.includes("{ Rate }")) {
                    ob = ob.replace('{ Rate }', '')
                }
                if (ob.includes("{ Payment }")) {
                    ob = ob.replace('{ Payment }', '')
                }
            }
            
            for (const key in this.DataBooking) {
                if(key != 'Caddy') {
                    if (Object.hasOwnProperty.call(this.DataBooking, key)) {
                        const element = this.DataBooking[key];
                        var exp = new RegExp(`{ ${key} }`, "g");
                        ob = ob.replace(exp, element);
                    }
                }
            }
            for (const key in this.BookingDetail) {
                if (Object.hasOwnProperty.call(this.BookingDetail, key)) {
                    const element = this.BookingDetail[key];
                    var exp = new RegExp(`{ ${key} }`, "g");
                    ob = ob.replace(exp, element);
                }
            }
            this.htmlEmail = ob
            const newBlob = new Blob([ob], {
                type: 'text/html',
            })
            this.urlPDF = window.URL.createObjectURL(newBlob)
            this.isLoadding = false
        },
        async EmailGroup() {
            this.isLoadding = true
            if (this.LangOfReport.LangId == 1000000) {
                var htmlBooked = this.listDataVie.find(x => x.KeyCode == "BOOKED_GROUP").KeyValue + this.listDataVie.find(x => x.KeyCode == "BOOKED_GROUP2").KeyValue
            } else {
                var htmlBooked = this.listDataEng.find(x => x.KeyCode == "BOOKED_GROUP").KeyValue + this.listDataEng.find(x => x.KeyCode == "BOOKED_GROUP2").KeyValue
            }
            var data = await this.unzipData(htmlBooked)
            this.replaceBookedGroup(data)
        },
        ExportPDF() {
            this.$refs.html2Pdf.generatePdf()
        },
        async beforeDownload({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(10)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                }
            }).save()
        },
        async unzipData(x) {
            const {gzip, ungzip} = require('node-gzip');

            let text = ''
            const gzipedData = Buffer.from(x, "base64");
            await ungzip(gzipedData)
                .then((decompressed) => {
                    text = decompressed.toString()
                });
            return text
        },
        replaceBookedGroupSelect(data) {
            this.TypeEmail = 'BOOKED_GROUP'
            var ob = data
            ob = ob.replace('{ ListMember }', document.getElementById('listMemberEmailSelect').innerHTML)
            ob = ob.replace('{ ListMemberVY }', document.getElementById('listMemberVYEmailSelect').innerHTML)
            if(this.isShowRate) {
                ob = ob.replace('{ Rate }', document.getElementById('RateEmailSelect').innerHTML)
                ob = ob.replace('{ Payment }', document.getElementById('PaymentEmailSelect').innerHTML)
            } else {
                ob = ob.replace('{ Rate }', '')
                ob = ob.replace('{ Payment }', '')
            }
            for (const key in this.DataBooking) {
                if (Object.hasOwnProperty.call(this.DataBooking, key)) {
                    const element = this.DataBooking[key];
                    var exp = new RegExp(`{ ${key} }`, "g");
                    ob = ob.replace(exp, element);
                }
            }
            this.htmlEmail = ob
            const newBlob = new Blob([ob], {
                type: 'text/html',
            })
            this.urlPDF = window.URL.createObjectURL(newBlob)
            this.isLoadding = false
        },
        EmailGroupSelect() {
            const listMemberSelect = this.listBookingDetail.filter(x => x.check).map(y => y.Id)
            if(listMemberSelect.length == this.listBookingDetail.length) {
                this.EmailGroup()
            } else if(listMemberSelect.length >= 1) {
                const RateInBookingtemp = this.RateInBooking.filter(x => listMemberSelect.find(y => y == x.BookingDetailId))
                const ServiceInBookingtemp = this.OtherServiceBooking.filter(x => listMemberSelect.find(y => y == x.BookingDetailId))
                var RateAll = []
                var ServiceAll = []
                var TotalGolfFee = 0
                var QuantityGolfFee = 0
                RateInBookingtemp.forEach(x => {
                    QuantityGolfFee++
                    var AmountRate = 0
                    x.Rate.forEach(y => {
                        TotalGolfFee += y.Amount
                        AmountRate += y.Amount
                    })
                    if (!RateAll.find(k => k.RateId === x.Rate[0].RateId)) {
                        RateAll.push({
                            RateId: x.Rate[0].RateId,
                            RateName: x.Rate[0].RateName,
                            AmountRate: AmountRate
                        })
                    }
                })
                ServiceAll.push({
                    ServiceId: -1,
                    ServiceName: 'Phí dịch vụ Golf',
                    TotalAmount: TotalGolfFee,
                    Quantity: QuantityGolfFee
                })
                ServiceInBookingtemp.forEach(x => {
                    x.Service.forEach(y => {
                        if (ServiceAll.length === 0) {
                            ServiceAll.push(JSON.parse(JSON.stringify(y)))
                        } else {
                            if (ServiceAll.find(z => z.ServiceId === y.ServiceId)) {
                                ServiceAll.forEach(k => {
                                    if (k.ServiceId === y.ServiceId) {
                                        k.Quantity += y.Quantity
                                        k.TotalAmount += y.TotalAmount
                                    }
                                })
                            } else {
                                ServiceAll.push(JSON.parse(JSON.stringify(y)))
                            }
                        }
                    })
                })
                this.dataEmailGroupSelect.Service = ServiceAll
                this.dataEmailGroupSelect.Member = this.listBookingDetail.filter(x => x.check)
                this.dataEmailGroupSelect.Rate = RateAll
                this.$nextTick(async () => {
                    this.isLoadding = true
                    if (this.LangOfReport.LangId == 1000000) {
                        var htmlBooked = this.listDataVie.find(x => x.KeyCode == "BOOKED_GROUP").KeyValue + this.listDataVie.find(x => x.KeyCode == "BOOKED_GROUP2").KeyValue
                    } else {
                        var htmlBooked = this.listDataEng.find(x => x.KeyCode == "BOOKED_GROUP").KeyValue + this.listDataEng.find(x => x.KeyCode == "BOOKED_GROUP2").KeyValue
                    }
                    var data = await this.unzipData(htmlBooked)
                    this.replaceBookedGroupSelect(data)
                })
            } else {
                return this.showToast('error', this.$t('golf_booking_please_select_atleast_one_person'))
            }
        },
        checkAllListBookingDetail(data) {
            this.listBookingDetail.forEach(x => {
                x.check = data
            })
        }
    }
}
</script>
<style lang="scss">
// @import "~@core/scss/base/themes/bordered-layout.scss";
.left-body {
    width: 25%;
}

.right-body {
    width: 75%;
}

.top-content {
    display: flex;
    justify-content: flex-end;

    .language-dropdown {
        list-style-type: none;
        padding: 0 0.5rem;
        line-height: 14px;

        a::after {
            display: none;
        }

        .dropdown-menu {
            min-width: auto !important;
            width: auto !important;
            top: 0px !important;
            left: 0 !important;
        }

    }
}

.vue-html2pdf .layout-container {
    display: contents !important;
}
</style>